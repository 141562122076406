/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Box, Text, Image } from "theme-ui"
import { animated, config, useSpring } from "react-spring"
import { AiOutlineRead } from "react-icons/ai"
import Tippy from "@tippyjs/react"
import "../styles/tippy.css"

//test
export default function AboutBooks({ currentBook, nextBook }) {
  const AnimatedBox = animated(Box)
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'perspective(600px) translate3d(0,15px,0)'},
    to: async (next) => {
      await next({ opacity: 1, transform: 'perspective(600px) translate3d(0,0,0)'})
    },
  });
  
  function Book(item) {
    return (
      <Tippy
        interactive={true}
        animation={"shift-away"}
        placement={"right-start"}
        arrow={false}
        content={
          <>
            <Box
              sx={{ fontSize: "0", overflow: "hidden", textAlign: "right" }}
              style={fade}
            >
              <Box sx={{ float: "left", pt: "1" }}>
                {item.currentBook === true ? (
                  <Text>I'm currently reading:</Text>
                ) : (
                  <Text>Next one on my list:</Text>
                )}
                <Text sx={{ fontSize: "1", fontWeight: "medium" }}>
                  {item.title}
                </Text>
                <Text sx={{ fontSize: "1" }}>by {item.authors}</Text>
              </Box>
              <Image
                sx={{
                  float: "left",
                  ml: "1",
                  boxShadow: "project.shadow",
                  transform:
                    "perspective(600px) rotateY(-30deg) rotateX(5deg) scaleY(.85) translateY(-10px)",
                }}
                src={item.thumbnail}
              ></Image>
            </Box>
          </>
        }
      >
        {item.currentBook === true ? (
          <Box sx={{ overflow: "hidden", width: "32px", float: "left" }}>
            <AiOutlineRead sx={{ fontSize: "5", fill: "primary" }} />
          </Box>
        ) : (
          <Box sx={{ overflow: "hidden", width: "32px", float: "right" }}>
            <AiOutlineRead sx={{ fontSize: "5", fill: "gray" }} />
          </Box>
        )}
      </Tippy>
    )
  }

  return (
    <AnimatedBox style={fade} sx={{width: "300px" }}>
      <Book sx={{ float: "left" }} {...currentBook}></Book>
      <Book sx={{ float: "right" }} {...nextBook}></Book>
    </AnimatedBox>
  )
}
