/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import React, {useState,} from 'react'
import { animated, useTransition, config, useSpring } from 'react-spring'
import Img from 'gatsby-image'

export default function AboutWhy({ circles, cover }) {
  const AnimatedBox = animated(Box)
  const AnimatedImg = animated(Img)
  const [index, set] = useState(0)
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: 'perspective(600px) rotateX(90deg)'},
    to: async (next) => {
      await next({ opacity: 1, transform: 'perspective(600px) rotateX(0deg)'})
    },
  });
  
  const handleClick = (event) => {
    event.preventDefault();
    set(event.target.getAttribute('data-id'))
  }

  const transitionCircles = useTransition(circles[index], {
    key: item => circles.indexOf(item),
    config: config.gentle,
    from: {
      position: 'absolute',
      opacity: 0.01,
      transform: 'perspective(600px) rotateX(90deg)',
    },
    enter: {
      opacity: 1,
      transform: 'perspective(600px) rotateX(0deg)',
    },
    leave: {
      opacity: 0.01,
      transform: 'perspective(600px) rotateX(-90deg)',
    },
  })

  return (
    <>
    <Box sx={{overflow:'hidden', mt:'5'}}>
      <AnimatedImg style={fade} sx={{borderRadius:'50%',zIndex:'-1', mt:'3'}} fixed={cover.childImageSharp.fixed}/>
      {transitionCircles((style, item) => {
        return (
          <AnimatedBox
            sx={{
              fontSize: '2',
              overflow: 'hidden',
              // position: 'absolute',
              // top: '80px',
              // left: '750px',
              display: 'inline-block',
              mx:['2','3','4','5'],
              width: '400px'
            }}
            style={style}
          >
            <Text sx={{fontSize: '4', color:'primary',fontWeight:'xbold'}}><i sx={{fontWeight:'300'}}>my</i> {item.title}</Text>
            <Text sx={{my:'2',fontSize: '3', lineHeight:'list', color:'text',fontWeight:'medium'}}>{item.summary}</Text>
            <hr/>
            <Text sx={{my:'1', color:'primary', fontWeight:'xbold',textTransform:'uppercase',textDecoration:'blink'}}>{item.subtitle}</Text>
            <Text sx={{fontSize: '1',fontWeight:'body', whiteSpace: 'pre-wrap'}}> {item.description} </Text>
          </AnimatedBox>
        )
      })}
      <AnimatedBox style={fade}  sx={{float:'right', mt:'3'}}>
      <svg width={300} height={300} >
        <circle
          onClick={handleClick}
          data-id='2'
          className={`${index}` !== '2' ?  "circles" : "circles active"}
          cx={150}
          cy={150}
          r={150}
        />
        <circle
          onClick={handleClick}
          data-id='1'
          className={`${index}` !== '1' ?  "circles" : "circles active"}
          cx={150}
          cy={150}
          r={100}
        />
        <circle
          onClick={ handleClick }
          data-id='0'
          className={`${index}` !== '0' ?  "circles" : "circles active"}
          cx={150}
          cy={150}
          r={50}
        />
        <text
          className='circlesText'
          textAnchor='middle'
          pointerEvents='none'
          x={150}
          y={155}
        >
          {'WHY'}
        </text>
        
        <text
          className='circlesText'
          textAnchor='middle'
          pointerEvents='none'
          x={150}
          y={227}
        >
          {'HOW'}
        </text>
        <text
          className='circlesText'
          textAnchor='middle'
          pointerEvents='none'
          x={150}
          y={280}
        >
          {'WHAT'}
        </text>
      </svg>
      </AnimatedBox>
      </Box>
    </>
  )
}
